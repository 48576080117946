import appStrings from '@/app/utility/string.utility'
export default {
    name: 'ChangePasswordForm',
    data() {
      return {
        loader: false,
        unsubscribe: null,
        showAlert: false,
        isSuccess: false,
        responseMsg: '',
        userName: null,
        currentPassword: '',
        newPassword: '',
        verifyNewPassword: '',
        modalShow: false
      };
    },
    mounted() {
      this.userName = this.getUserName();
    },
    methods: {
      getUserName() {
        const tempName = this.$store.state.auth.userName;
        return tempName;
      },
      handleOnChange: function(value) {
        this.newPassword = value;
        this.error = {
          setPassword: ''
        };
        this.reset = false;
      },
      handleOnComplete: function(value) {
        this.currentPassword = value;
      },
      handleOnCompleteSetPassword: function(value) {
        this.verifyNewPassword = value;
        if (this.verifyNewPassword != this.newPassword) {
          this.error = {
            setPassword: 'Password not match'
          };
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = this.error.setPassword;
        } else {
          this.changePassword();
        }
      },
      changePassword() {
        const payload = {
          old_password: this.currentPassword,
          new_password: this.newPassword
        };
        this.loader = true;
        this.$store.dispatch('auth/changeExistingPassword', payload)
        .then(response => {
          this.loader = false;
          if(response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.modalShow = true;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message || appStrings.autoFailedMsg;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        })
      },
      singout: function () {
        this.$store.dispatch('auth/logout').then(() => {
          this.leList = null;
          location.reload(true);
        });
      }
    }
  };